import React from 'react';
import Layout from '../components/layout';
import Nav from '../components/Nav';
import ProjectsData from '../components/Projects';
import page from '../HOCs/page';

const Projects = () => {
  return (
    <Layout>
      <Nav currentLocation="Work" returnLocation="home" />
      <ProjectsData />
    </Layout>
  );
};

export default page(Projects, 'work');
