import React from 'react';
import Fade from 'react-reveal/Fade';
import { MessagesInAlert } from '../Messages';
import { ProgressBar } from '../common/loading/loading';
import { useApi } from '../../hooks';
import styles from './Projects.module.scss';

const Shell = ({ children }) => <>{children}</>;

const ErrorShell = () => (
  <section className={styles.errorShell}>
    <h3>Could not load projects at this moment.</h3>
  </section>
);

const LoadingShell = () => (
  <div className={styles.loadingShell}>
    <ProgressBar />
  </div>
);

Shell.Error = ErrorShell;
Shell.Loader = LoadingShell;

const ProjectsData = () => {
  const { isLoading, hasErrors, data } = useApi('projects');

  const projects = data?.projects?.map((project) => {
    const {
      description,
      title: name,
      live_link: linkToLiveProject,
      repo_link: linkToRepository,
    } = project;
    const link = linkToLiveProject || linkToRepository;

    return (
      <Fade top cascade distance="10px" key={name}>
        <section className={styles.innerContainer}>
          <h1 className={styles.title}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="project-link"
              href={link}
            >
              {name}
            </a>
          </h1>
          <span className={styles.description}>— {description}</span>
        </section>
      </Fade>
    );
  });

  if (hasErrors) return <Shell.Error />;

  return (
    <div className={styles.container}>
      {isLoading ? <Shell.Loader /> : <div id="projects">{projects}</div>}
      <MessagesInAlert />
    </div>
  );
};

export default ProjectsData;
