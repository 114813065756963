import React from 'react';
import PropTypes from 'prop-types';

// Shared components.
import ProgressMessages from '../message/message';

// Stylesheets.
import styles from './loading.module.scss';

/**
 * Circular progress indicator based on Material Design.
 * @credits {@link https://codepen.io/finnhvman Ben Szabo}
 * @reference {@link https://codepen.io/finnhvman/pen/bmNdNr Codepen}
 */
const Loading = ({ messages, timeout }) => (
  <div className={styles.loader}>
    <progress className={styles.pureMaterialProgressCircular}></progress>
    {messages && <ProgressMessages messagesList={messages} timeout={timeout} />}
  </div>
);

export const ProgressBar = () => (
  <progress className={styles.pureMaterialProgressCircular}></progress>
);

Loading.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.string),
  timeout: PropTypes.number,
};

export default Loading;
