import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import styles from './message.module.scss';

/**
 * Displays messages under the progress indicator.
 * @param {Object} props - Properties passed.
 * @param {string[]} props.messagesList - List of messages to be displayed.
 * @param {number} props.timeout - Time difference to show each message.
 */
const ProgressMessages = ({ messagesList, timeout }) => {
  let [currentMessage, setCurrentMessage] = useState(messagesList[0]);

  useEffect(() => {
    /**
     * Set current message based on index.
     * @param {number} index - Index of the list to set message.
     */
    const setMessage = (index) => {
      setCurrentMessage(messagesList[index]);
    };

    let index = 1;
    let timer;

    if (index < messagesList.length - 1) {
      timer = setInterval(() => {
        setMessage(index);
        index += 1;
      }, timeout);
    }

    return () => {
      clearInterval(timer);
      index = 0;
    };
  }, [timeout, messagesList]);

  return <p className={styles.message}>{currentMessage}</p>;
};

ProgressMessages.propTypes = {
  messagesList: PropTypes.arrayOf(PropTypes.string).isRequired,
  timeout: PropTypes.number.isRequired,
};

ProgressMessages.defaultProps = {
  messagesList: ['Loading...'],
  timeout: 2000,
};

export default ProgressMessages;
